<template>
  <div class="my-5 mr-5">
    <p v-if="store.subscription_slug == 'pro'">
      Feel free to explore Bidbrain, we recommend you to dig into your analysis or running the feed checker
    </p>
    <p v-else>
      Feel free to explore Bidbrain, we recommend you to dig into your analysis or create your first Bidbrain campaign
    </p>
    <div class="flex flex-wrap mt-5 gap-3">
      <the-button
        @click.stop="$router.push({ name: 'analysis' })"
        secondary
      >
        Check Feed Analysis
      </the-button>
      <the-button
        @click.stop="$router.push({ name: 'campaign:feed' })"
        secondary
      >
        Run feed check
      </the-button>
      <the-button
        @click.stop="$router.push({ name: 'store:settings:bidbraincss' })"
        secondary
      >
        Use Bidbrain CSS
      </the-button>
      <the-button
        v-if="store.subscription_slug != 'pro'"
        @click.stop="$router.push({ name: 'campaigns:create' })"
        primary
      >
        Create Bidbrain Campaign
        <template #icon>
          <ic-chevron-right :size="18" />
        </template>
      </the-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'

export default {
  name: 'StepCompleted',
  components: { IcChevronRight },
  props: {
    allTaskCompleted: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
    }),
  },
}
</script>
